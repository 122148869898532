import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import tw from "twin.macro"

const Pager = ({ pageContext }) => {
  const { previousPagePath, nextPagePath } = pageContext
  return (
    <div tw="flex justify-between">
      {previousPagePath ? (
        <Link to={previousPagePath}>
          <button tw="shadow-md border rounded-md p-3 my-4 text-left mr-2">
            ←前の記事
          </button>
        </Link>
      ) : (
        <div></div>
      )}
      {nextPagePath && (
        <Link to={nextPagePath}>
          <button tw="shadow-md border rounded-md p-3 my-4 text-right ml-2">
            次の記事→
          </button>
        </Link>
      )}
    </div>
  )
};

Pager.propTypes = {
  pageContext: PropTypes.object.isRequired
}

export default Pager;
