import React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import tw from "twin.macro"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Pager from "../components/pager"
import FeaturedPost from "../components/FeaturedPost"

const H1 = tw.h1`
  mt-10
  text-2xl
`

const BlogIndex = ({ data }) => {
  const posts = data.allMicrocmsPosts.edges
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const pageContext = { nextPagePath: "/2" }

  return (
    <Layout title={siteTitle}>
      <SEO title="茨城夫婦のグルメブログ" />
      <FeaturedPost />
      <H1 tw="mt-10">新着記事一覧</H1>
      <ol tw="divide-y mb-20" style={{ listStyle: `none` }}>
        {posts.map(item => {
          const post = item.node
          const title = post.title
          const image = getImage(post.localImage)

          return (
            <li key={post.postsId}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <Link to={`/posts/${post.postsId}`} itemProp="url">
                    <div tw="sm:flex sm:flex-wrap">
                      <picture tw="w-full sm:w-1/2 max-w-xs">
                        <GatsbyImage
                          image={image}
                          alt={post.localImage}
                          fullWidth
                          tw="rounded-md mx-auto object-cover "
                        />
                      </picture>
                      <div tw="w-full sm:w-1/2 mx-auto sm:mx-0 sm:pl-4 pt-2">
                        <h2 tw="text-xl">{title}</h2>
                        <span>{post.publishedAt}</span>
                      </div>
                    </div>
                  </Link>
                </header>
              </article>
            </li>
          )
        })}
      </ol>
      <Pager pageContext={pageContext} />
    </Layout>
  )
}

export const query = graphql`
  query BlogPostIndex {
    allMicrocmsPosts(
      filter: { isFeatured: { eq: false } }
      sort: { fields: [publishedAt], order: DESC }
      limit: 10
    ) {
      edges {
        node {
          title
          author
          category
          postsId
          image {
            url
          }
          localImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          publishedAt(formatString: "YYYY/MM/DD", locale: "ja")
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
export default BlogIndex
