import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import tw from "twin.macro"

const Picture = tw.picture`
  w-full
  sm:pr-4
  relative
`

const FeaturedPost = () => {
  const data = useStaticQuery(graphql`
    {
      featured: allMicrocmsPosts(
        filter: { isFeatured: { eq: true } }
        limit: 1
      ) {
        nodes {
          title
          postsId
          localImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
          publishedAt(formatString: "YYYY/MM/DD", locale: "ja")
        }
      }
    }
  `)

  const {
    featured: { nodes },
  } = data
  if (!nodes) {
    return null
  }
  const post = nodes[0]
  const title = post.title
  const image = getImage(post.localImage)

  return (
    <article>
      <header>
        <Link to={`/posts/${post.postsId}`} itemProp="url">
          <div tw="flex flex-wrap">
            <Picture>
              <GatsbyImage
                image={image}
                alt={post.localImage}
                tw="border-8 border-pink-300 rounded-md mx-auto object-cover "
              />
              <div tw="absolute top-0 left-0 pl-10 pr-8 py-2 sm:py-3 text-black bg-pink-300 text-base sm:text-lg sm:text-2xl rounded-lg">
                PICK UP
              </div>
            </Picture>
            <div tw="w-full mx-auto sm:pr-0 md:pr-4 pt-4">
              <h2 tw="text-2xl text-blue-900">{title}</h2>
              <span tw="p-0 sm:p-4">{post.publishedAt}</span>
            </div>
          </div>
        </Link>
      </header>
    </article>
  )
}

export default FeaturedPost
